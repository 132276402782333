<template>
  <div
    class="tl can-res text-center position-relative"
    :style="{width: width}"
    @dragenter="setDragenterRowAndIndex"
  >
    <v-icon v-if="dayOff" style="opacity: 0.5" name="fa-calendar-times" scale="4"/>
    <div class="day-off-reason">{{ reasonType[dayOff] }}</div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    rowIndex: Number,
    keyIndex: Number,
    width: String,
    dayOff: Number,
  },
  data() {
    return {
      reasonType: [
          "", "PTO", "Requested Off"
      ]
    }
  },
  methods: {
    setDragenterRowAndIndex() {
      this.$emit("set-dragenter-row-and-index", this.rowIndex, this.keyIndex);
    },
  }
}
</script>

<style scoped>
@import "schedule.css";
</style>